import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 13; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/11-sep-1/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  for (let i = 1; i <= 12; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/11-sep-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function Janmashatami2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Krishna Janmashtami Celebrations</h1>

        <p>
          The learners of the Primary Department of Chinmaya Vidyalaya, New
          Delhi celebrated Janmashtami with great fervour and gaiety on 6th
          September 2023.
          <br />
          <br />
          The learners were apprised about the life and teachings of Krishna.The
          students also learnt that the joyous festival symbolises the
          encouragement of goodwill in order to establish Dharma.
          <br />
          <br />
          The little munchkins of classes I-II made peacock feather
          paper-headgears and decorated cutouts of matkis (pots) with sequins,
          mirrors and colourful stones. They relished drinking Krishna’s
          favourite drink, buttermilk. The learners of classes III enjoyed
          making beautiful Janmasthami cards. The students of classes IV-V
          transformed the school corridors into a land of joy with their
          colourful rangolis made with colours, flowers and leaves.
          <br />
          <br />
          The little learners of Pre- Primary came in traditional attires and
          relished parantha with butter. They were shown a tableau that depicted
          the scene of Janmashtami.
          <br />
          <br />
          The involvement of the students in the celebrations was worth
          applauding and brought devotion, creativity and enjoyment all
          together.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
